import { useRef, useEffect, useState } from 'react';
import { useLocation, useNavigate, NavigateFunction, useMatches, Location, } from "react-router-dom";

import { clsx } from "clsx";

import { Button } from "primereact/button";
import { useMediaQuery } from "react-responsive";
import { Dropdown } from "primereact/dropdown";

import Traversal from 'components/Traversal';

import styles from './SecondaryNavigation.module.scss';

// TODO - better typing of the items
interface ISecondaryNavigationProps {
	items: any[];
	urlParam?: string;
	onBeforeNavigation?: () => Promise<void>;
	pathKey?: string;
}

function SecondaryNavigation(args: ISecondaryNavigationProps) {

	const { items, urlParam, onBeforeNavigation, pathKey } = args;

	const matches = useMatches();

	const currentLoc: Location = useLocation();
	const navigate: NavigateFunction = useNavigate();
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });

	const [ currentActiveItem, setCurrentActiveItem ] = useState<any>();
	const [ traversalVisible, setTraversalVisible ] = useState<boolean>(false);

	const additional: string = urlParam ? `/${urlParam}` : '';
	const navRef = useRef<HTMLDivElement>(null);

	useEffect(() => {

		const { pathname } = currentLoc;

		setCurrentActiveItem(items.find((i => pathname.indexOf(i.path ?? i[pathKey as keyof typeof i ]) > 0)));

	}, [currentLoc, items, pathKey]);

	const doNavigate = (path: string): void => {
		if (typeof onBeforeNavigation === 'function') {
			onBeforeNavigation().finally(() => {
				navigate(path);
			});

			return;
		}

		navigate(path);
	}

	return <>

		{isTabletOrMobile &&

			<Dropdown
				className={styles.dropdown}
				onChange={(e) => doNavigate(`${matches[1].pathname}/${e.value.path ?? e.value[pathKey as keyof typeof e.value]}${additional}`)}
				value={currentActiveItem}
				options={items}
			/>

		}
		{!isTabletOrMobile &&
		<div>
			<Traversal container={navRef} />
			<div
				ref={navRef}
				className={clsx(styles.container, 'overflow--hidden')}>
				{ items?.map((item: any) => {
					let { icon, path, label } = item;

					if (!path) {
						//	no item at key 'path' so use the pathKey prop
						path = item[pathKey as keyof typeof item]
					}

					if (icon) {
						icon = `${icon} icon--tiny`
					}
					return <Button
						key={crypto.randomUUID()}
						icon={icon}
						className={clsx(
							'p-button--tab-style',
							styles.menuItem,
							{ 'active': currentLoc.pathname.indexOf(path) > 0 }
						)}
						onClick={() => doNavigate(`${matches[1].pathname}/${path}${additional}`)}
					>
						{label}
					</Button>
				})}
			</div>
		</div>
		}
	</>

}

export default SecondaryNavigation;
