import { useEffect, useState } from 'react';
import { type NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';

import { useGetWorkflowTemplates } from 'modules/Workflows/Services/WorkflowApi';
import { newWorkflowValidator, templateValidator } from 'modules/Workflows/Models/Validators';
import { WorkflowProvider } from 'modules/Workflows/Models/WorkflowProvider';
import { WorkflowStatusTypeEnum } from 'modules/Workflows/Models/WorkflowStatusTypeEnum';
import { COMISSION_RATE_OPTIONS, COMMISSION_RATE_FIELD, COMMISSION_RATE_VALUE_FIELD, CommissionRateType } from 'modules/Workflows/Models/CommissionRate';

import type { ApiWorkflowPropertyKvp } from 'modules/Workflows/Models/ApiWorkflowPropertyKvp';
import type { ApiWorkflowTemplateResponse } from 'modules/Workflows/Models/ApiWorkflowTemplateResponse';
import type { ValidationError } from 'joi';

import './CreateWorkflow.scss';

interface CreateWorkflowProps {
  getProviderId: () => number;
  isLoading: boolean;
  startWorkflow: (templateName: string, properties: ApiWorkflowPropertyKvp<string, string>[] | undefined) => Promise<void>;
  closePanel: () => void;
};

export default function CreateWorkflow(props: CreateWorkflowProps): JSX.Element {
  const { getProviderId, isLoading, startWorkflow, closePanel } = props;

  const { providerId } = useParams();
  const navigate: NavigateFunction = useNavigate();
  const { templatesData } = useGetWorkflowTemplates();
  const [templates, setTemplates] = useState<ApiWorkflowTemplateResponse[]>([]);
  const [currentTemplate, setCurrentTemplate] = useState<ApiWorkflowTemplateResponse>();
  const [newWorkflowProperties, setNewWorkflowProperties] = useState<ApiWorkflowPropertyKvp<string, string>[]>();
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string; } | null>(null);
  const [isWarningDialogVisible, setIsWarningDialogVisible] = useState<boolean>(false);
  const [formTouched, setFormTouched] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  useEffect(() => {
    if (!providerId) {
      navigate(`/workflows/${WorkflowProvider.Generic}/${WorkflowStatusTypeEnum.NotStarted}`);
    }

    if (templatesData) {
      setTemplates(templatesData.filter(y => y.linkedProvider.toString() === providerId));
    }

  }, [navigate, providerId, templatesData]);

  useEffect(() => {
    if (currentTemplate && currentTemplate.template) {
      setFormTouched(true);
      setNewWorkflowProperties(currentTemplate.template.properties);
    }
  }, [currentTemplate])

  useEffect(() => {
    if (showValidationErrors) {
      validate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showValidationErrors, newWorkflowProperties]);

  const validate = async (): Promise<boolean> => {
    try {
      await templateValidator.validateAsync({ Template: currentTemplate?.templateName ?? '' }, { convert: false, abortEarly: false });
    } catch (e) {
      setValidationErrors({ Template: (e as ValidationError).details[0].message });
      return false;
    }

    if (newWorkflowProperties?.length) {
      try {
        // reduce properties array to the object `{ [key]: value }` to simplify the validator object and error displaying
        const properties = newWorkflowProperties.reduce((acc, curr) => ({ ...acc, [curr.key]: curr.value }), {});

        await newWorkflowValidator.validateAsync(properties, { convert: false, abortEarly: false });
      } catch (e) {
        const errors = newWorkflowProperties.reduce((acc, curr) =>
          ({ ...acc, [curr.key]: (e as ValidationError).details.find(e => e.context?.key === curr.key)?.message }), {});

        setValidationErrors(errors);

        return false;
      }
    }

    setValidationErrors(null);

    return true;
  }

  const handleTemplateSelected = (templateName: string) => {
    setCurrentTemplate(templates.find(x => x.templateName === templateName));
  }

  const updateNewProperty = (key: string, value: string) => {
    if (newWorkflowProperties) {
      setNewWorkflowProperties(newWorkflowProperties.map(property => property.key === key ? { key, value } : property));
    }
  }

  const saveNewWorkflow = async (): Promise<void> => {
    const valid = await validate();

		console.log("valid....", valid);
    if (!valid) {
      setShowValidationErrors(true);
      return;
    }

    if (currentTemplate && getProviderId()) {
      await startWorkflow(currentTemplate.templateName, newWorkflowProperties);
    }
  }

  const handleClosePanel = (): void => {
    if (formTouched) {
      setIsWarningDialogVisible(true);
    } else {
      closePanel();
    }
  }

  const renderNewWorkflowPropertiesInputs = ({ key, value }: ApiWorkflowPropertyKvp<string, string>, pKey: number) => {
    // do not render input for 'Commission rate value'
    if (key === COMMISSION_RATE_VALUE_FIELD) {
      return null;
    }

    const validationError = (showValidationErrors && validationErrors) ? validationErrors[key] : null;

    let input;

    switch (key) {
      case 'Notes':
        input = <InputTextarea
          className={clsx({ 'p-invalid': validationError })}
          rows={3}
          onChange={e => updateNewProperty(key, e.currentTarget.value)}
          value={value}
        />;
        break;
      case COMMISSION_RATE_FIELD:
        const errorForCustomRate = validationErrors?.[COMMISSION_RATE_VALUE_FIELD];

        input = <>
          <Dropdown
            className={clsx({ 'p-invalid': validationError })}
            onChange={(e) => updateNewProperty(key, e.value)}
            options={COMISSION_RATE_OPTIONS}
            placeholder='Select commission rate'
            value={value}
          />
          {value === CommissionRateType.Custom && <>
            <div className={clsx('p-inputgroup custom-rate-input-field', { 'p-invalid': errorForCustomRate })}>
              <InputText
                keyfilter='num'
                type='number'
                onChange={e => updateNewProperty(COMMISSION_RATE_VALUE_FIELD, e.target.value)}
                placeholder='Add custom rate'
              />
              <span className="p-inputgroup-addon">%</span>
            </div>
            {errorForCustomRate && <small className='message-invalid'>{errorForCustomRate}</small>}
            </>}
          </>;
          break;
        default:
          input = <InputText
            className={clsx({ 'p-invalid': validationError })}
            onChange={e => updateNewProperty(key, e.currentTarget.value)}
            value={value}
          />;
          break;
      }

    return <div key={pKey} className={clsx('form-input__container', { 'p-invalid': validationError })}>
      <label>{key}</label>
      {input}
      {validationError && <small className='message-invalid'>{validationError}</small>}
    </div>
  }

  return <div className='grow-to-fill direction--column create-workflow'>
    <header className='create-workflow__header'>
      {isMobile ?
        <Button
          text
          size='small'
          onClick={handleClosePanel}
          icon='iconoir-nav-arrow-left icon--small'
          className='workflows-page__back-button plain-text'
        >
          Back to list
        </Button>
      :
        <>
          <span>Add Workflow</span>
          <Button
            text
            icon='iconoir-xmark icon--tiny p-button-icon-only'
            className='close-button'
            onClick={handleClosePanel}
          />
        </>}
    </header>
    <div className='create-workflow__container'>
      <h1>Add Workflow</h1>
      <form>
        <div className='form-input__container'>
          <label>Template</label>
          <Dropdown
            className={clsx({ 'p-invalid': validationErrors?.Template })}
            value={currentTemplate?.templateName}
            placeholder='Select template'
            onChange={(e) => handleTemplateSelected(e.value)}
            options={templates.map((template) => ({ label: template.templateName, value: template.templateName }))}
          />
          {validationErrors?.Template && <small className='message-invalid'>{validationErrors.Template}</small>}
        </div>
        {newWorkflowProperties && newWorkflowProperties.map(renderNewWorkflowPropertiesInputs)}
      </form>
    </div>
    <footer>
      <Button
        size='small'
        severity='success'
        loading={isLoading}
        onClick={saveNewWorkflow}
      >
        SAVE
      </Button>
    </footer>

    <Dialog
      appendTo='self'
      position='top'
      className='p-dialog--margin-less p-dialog--no-header create-workflow__warning-dialog'
      visible={isWarningDialogVisible}
      footer={<div className='space-between'>
        <Button
          size='small'
          outlined
          severity='danger'
          onClick={closePanel}
        >
          Continue
        </Button>
        <Button
          size='small'
          text
          onClick={() => setIsWarningDialogVisible(false)}
        >
          Return
        </Button>
      </div>}
      onHide={() => setIsWarningDialogVisible(false)}
    >
      <strong>You have unsaved changes</strong>
      <div>If you CONTINUE any changes you've made will be lost. Hit RETURN too complete.</div>
    </Dialog>
  </div>;
}