import { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import clsx from 'clsx';

import EditableContent from 'modules/Workflows/Components/EditableContent';

import type { ApiWorkflowResponse } from 'modules/Workflows/Models/ApiWorkflowResponse';

import './WorkflowName.scss';

interface WorkflowNameProps {
    handleRename: (wf: ApiWorkflowResponse, value: string) => Promise<void>;
    isRenaming: boolean;
    workflow: ApiWorkflowResponse;
};

export default function WorkflowName(props: WorkflowNameProps): JSX.Element {
    const { handleRename, isRenaming, workflow } = props;

    const [value, setValue] = useState<string>(workflow.title);

    const rename = async (): Promise<boolean> => {
        try {
            await handleRename(workflow, value);
            return true;
        } catch (e) {
            return false;
        }
    };

    const handleEditModeChange = (editing: boolean): void => {
        if (!editing) {
            setValue(workflow.title);
        }
    }

    return <div className='workflow-name__container'>
        <EditableContent
            onModeChange={handleEditModeChange}
            save={rename}
            saveButtonDisabled={!value}
            saveButtonLoading={isRenaming}
            value={workflow.title}
        >
            <InputText
                autoFocus
                className={clsx({ 'p-invalid': !value })}
                defaultValue={workflow.title}
                onChange={(e) => setValue(e.target.value.trim())}
            />
            {/* TODO: could use `Joi.valideAsync` to setup errors but for now it's would be overkill  */}
            {!value && <small className='message-invalid'>Required field</small>}
        </EditableContent>
    </div>
}