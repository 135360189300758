import { Button } from 'primereact/button';
import { MultiSelect, type MultiSelectChangeEvent } from 'primereact/multiselect';

import './WorkflowDetailsFilterOption.scss';

export type FilterOptionValue = {
  label: string;
  value: string | number;
};

interface FilterOptionProps {
  options: FilterOptionValue[];
  placeholder: string;
  setValue: (value: FilterOptionValue[]) => void;
  values: FilterOptionValue[];
}

export default function FilterOption(props: FilterOptionProps): JSX.Element {
  const { options, placeholder, setValue, values } = props;

  return <MultiSelect
    appendTo='self'
    className='workflow-details-filter-option'
    onChange={(e: MultiSelectChangeEvent) => setValue(e.value)}
    options={options}
    panelFooterTemplate={<div className='align--right grow-to-fill'>
      <Button
        text
        size='small'
        disabled={!values.length}
        onClick={() => setValue([])}
      >
        Clear
      </Button>
    </div>}
    panelHeaderTemplate={<></>}
    placeholder={placeholder}
    optionLabel='label'
    selectedItemsLabel={`${values[0]?.label} ${values.length - 1 ? `(+${values.length - 1})` : ''}`}
    maxSelectedLabels={1}
    showSelectAll={false}
    useOptionAsValue
    value={values}
  />;
}