import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { AutoCompleteCompleteEvent } from 'primereact/autocomplete';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';

import ItemTemplate, { ItemTemplateProps } from 'components/Autocomplete/Templates/ItemTemplate';
import DateTimeRange from 'components/DateTimeRange';
import { ParssedDateTimeResult } from 'components/DateTimeRange/Services/ConvertString';
import {
  EntitySearchFieldsEnum,
  EntitySearchGroupEnum,
} from 'components/EntitySearch/Models/Enums';
import SingleEntitySearch from 'components/EntitySearch/SingleEntitySearch';
import { Flag } from 'components/Flag';
import LimitedLenghtInput from 'components/LimitedLenghtInput';
import { useLoggedInUser } from 'components/OBXUser/Services/ProfileHooks';
import QuantityParser from 'components/QuantityParser/QuantityParser';

import { PositionConfidentialityEnum } from '../../../PositionsList/Models/Enums';
import { CargoUpdateRequest } from '../../Models/CargoTrackerRequest';
import { StatusEnum, UnitEnum } from '../../Models/Enums';
import { chartererEntityMapper, commodityEntityMapper, SuggestionResponseCommodity } from '../../Models/Mappers';
import GroupedSearch, { SuggestionResponse, SuggestionResponseLocation } from '../GroupedSearch';

import { EnumKeys, getKeyValuePairs } from 'helpers/Utils/enum';
import { laycanStyleRange } from 'helpers/Utils/formatters';
import { notNil } from 'helpers/Utils/misc';
import { formatName } from 'helpers/Utils/string';
import {
  Commodity,
  COMMODITY_KEYS,
  LocationType,
  PetroleumProductEnum
} from 'modules/CargoTracker/Models/CargoTrackerResponse';
import AssignToMe from 'components/AssignToMe';
import { portLocationEntityMapper } from 'modules/PositionsList/Models/Mappers';

interface ICargoEditSpotProps {
  request: CargoUpdateRequest;
  mutateCargo: (mutation: Partial<CargoUpdateRequest>) => void;
  showErrors: boolean;
  setIsParsing: Dispatch<SetStateAction<boolean>>;
}

const CargoEditSpot = (props: ICargoEditSpotProps):JSX.Element => {
  const { request, mutateCargo, showErrors, setIsParsing } = props;
  const [tempValue, setTempValue] = useState<string | undefined>(undefined);
  const statuses: EnumKeys[]  = getKeyValuePairs(StatusEnum, true);
  const { obxuser } = useLoggedInUser();

  // If C/D Petroleum Product Type is N/A -> update do unknown value for spot
  useEffect(() => {
    if (request.petroleumProductType !== PetroleumProductEnum.NA) {
      mutateCargo({petroleumProductType: PetroleumProductEnum.NA});
    }
  }, [mutateCargo, request.petroleumProductType]);

  useEffect(() => {
    // Add temp value first to update initialTerm (issue when selecting same values but with different IDs)
    if (tempValue) {
      setTempValue(undefined);
    }
  }, [tempValue]);

  const handleChartererChange = (change?: SuggestionResponse): void => {
    mutateCargo({
      charterer: change?.value ?? change?.searchTerm ?? '',
      chartererSearchId: change?.searchEntityId ?? null,
    });
  };

  const handleChartererChangeFreeInput = (change?: string): void => {
    mutateCargo({
      charterer: change ?? '',
      chartererSearchId: null,
    });
  };

  const getCommodityValues = (entityName: string, change: SuggestionResponseCommodity): Commodity => ({
    [`${ entityName }`]: change.IsAlias ? change.FullName : change.value,
    [`${ entityName }SearchId`]: change.searchField === EntitySearchFieldsEnum.ProductGrade ? change.searchEntityId : undefined,
    [`${ entityName }Alias`]: change.IsAlias ? change.value : undefined
  });

  const commodityInitialValue: string = useMemo(
    (): string => {
      const foundKey = COMMODITY_KEYS.find(key => notNil(request?.commodity?.[key as keyof Commodity]));
      return foundKey ? `${ request?.commodity?.[foundKey as keyof Commodity] }` : '';
    },
    [request?.commodity]
  );

  const handleCommodityChange = (change?: SuggestionResponseCommodity): void => {
    let commodity;
    switch (change?.searchField) {
    case EntitySearchFieldsEnum.ProductGrade:
      commodity = getCommodityValues('productGrade', change);
      break;
    case EntitySearchFieldsEnum.ProductCategory:
      commodity = getCommodityValues('productCategory', change);
      break;
    case EntitySearchFieldsEnum.ProductGroup:
      commodity = getCommodityValues('productGroup', change);
      break;
    case EntitySearchFieldsEnum.Product:
      commodity = getCommodityValues('product', change);
      break;
    }

    mutateCargo({ commodity });
  };

  const handleCommodityChangeFreeInput = (e: AutoCompleteCompleteEvent): void => {
    mutateCargo({ commodity: { productGrade: e.query ?? '' } });
  };

  return <>
    <div className="form-input__container">
      <label htmlFor="spot-code">Cargo Code</label>
      <LimitedLenghtInput
        id="spot-code"
        key={`spot-code-${ request.id }`}
        className="spot-code-input"
        currentValue={request?.code ?? ''}
        callback={(value):void => mutateCargo({ code: `${ value }` })}
        maxlength={50}
        showError={showErrors}
        callbackOnChange={true}
        showCounter={false}
        preventInputOverflow={false}
      />
    </div>
    <div className="form-input__container">
      <label htmlFor="spot-commodity">Commodity*</label>
      <GroupedSearch
        id="spot-commodity"
        panelClassName="cargo-edit__autocomplete"
        module={EntitySearchGroupEnum.CargoTracker}
        fields={[ EntitySearchFieldsEnum.Product, EntitySearchFieldsEnum.ProductGroup, EntitySearchFieldsEnum.ProductCategory, EntitySearchFieldsEnum.ProductGrade ]}
        initialTerm={commodityInitialValue}
        mutate={handleCommodityChange}
        inputProps={{ completeMethod: handleCommodityChangeFreeInput }}
        mapper={commodityEntityMapper}
        showError={showErrors}
      />
    </div>
    <div className="form-input__container">
      <label htmlFor="spot-quantity">Quantity*</label>
      <QuantityParser
        id="spot-quantity"
        key={`spot-quantity-${ request.id }`}
        defaultValue={request?.quantity?.original ?? null}
        onRangeParsed={(parsed):void => {
          mutateCargo(parsed ? { quantity:
              { ...parsed, unit: request?.quantity?.unit ?? UnitEnum.MT }
          } : { quantity: undefined });
          setIsParsing(false);
        }}
        addonText={UnitEnum[request?.quantity?.unit ?? UnitEnum.MT]}
        showError={showErrors}
        onFocus={():void => setIsParsing(true)}
      />
    </div>
    <div className="form-input__container">
      <label htmlFor="spot-loading">Loading*</label>
      <GroupedSearch
        id="spot-loading"
        panelClassName="cargo-edit__autocomplete"
        module={EntitySearchGroupEnum.PositionList} // CargoTracker?
        fields={[ EntitySearchFieldsEnum.Port, EntitySearchFieldsEnum.Country ]}
        initialTerm={request?.loading?.portName ?? request?.loading?.portCountry}
        mutate={(change?:SuggestionResponseLocation):void =>
          mutateCargo(change ? { loading: {
            locationType: LocationType.Search,
            portSignalOceanId: change.SignalOceanPortId,
            flagCode: change.CountryCodeISO3,
            portName: change.searchField === EntitySearchFieldsEnum.Port ? change.value : undefined,
            portCountry: change.searchField === EntitySearchFieldsEnum.Country ? change.value : undefined } } :
            { loading: undefined } )
        }
        mapper={portLocationEntityMapper}
        addOn={ request?.loading?.flagCode ? <Flag code={request?.loading?.flagCode} /> : null }
        showError={showErrors}
      />
    </div>
    <div className="form-input__container">
      <label htmlFor="spot-discharging">Discharging*</label>
      <GroupedSearch
        id="spot-discharging"
        panelClassName="cargo-edit__autocomplete"
        module={EntitySearchGroupEnum.PositionList} // CargoTracker?
        fields={[ EntitySearchFieldsEnum.Port, EntitySearchFieldsEnum.Country ]}
        initialTerm={request?.discharging?.portName ?? request?.discharging?.portCountry}
        mutate={(change?:SuggestionResponseLocation):void =>
          mutateCargo(change ? { discharging: {
            locationType: LocationType.Search,
            portSignalOceanId: change.SignalOceanPortId,
            flagCode: change.CountryCodeISO3,
            portName: change.searchField === EntitySearchFieldsEnum.Port ? change.value : undefined,
            portCountry: change.searchField === EntitySearchFieldsEnum.Country ? change.value : undefined } } :
            { discharging: undefined })
        }
        mapper={portLocationEntityMapper}
        addOn={ request?.discharging?.flagCode ? <Flag code={request?.discharging?.flagCode} /> : null }
        showError={showErrors}
      />
    </div>
    <SingleEntitySearch
      label='Charterer*'
      module={EntitySearchGroupEnum.CargoTracker}
      fields={EntitySearchFieldsEnum.Charterer}
      initialTerm={request?.charterer ?? ''}
      itemTemplate={(i: ItemTemplateProps):JSX.Element => ItemTemplate(i, chartererEntityMapper)}
      onInputClear={():void => mutateCargo({ charterer: '', chartererSearchId: null })}
      callback={handleChartererChange}
      showError={showErrors}
      errorVisibleAfterTouch={false}
      completeMethod={handleChartererChangeFreeInput}
      panelClassName="spot-charterer"
    />
    <DateTimeRange
      label='Laycan*'
      key={`laycan-${ request.id }`}
      onEmptyValue={(value: string):void => {
        mutateCargo({ laycan: { original: value, fromDate: '', toDate: '' } });
        setIsParsing(false);
      }}
      onDateParsed={(m: ParssedDateTimeResult):void => {
        mutateCargo({ laycan: {
          original: m.original,
          fromDate: m.fromString,
          toDate: m.toString,
          fromDateParsed: m.from,
          toDateParsed: m.to
        }});
        setIsParsing(false);
      }}
      defaultValue={
        request?.laycan?.fromDateParsed && request?.laycan?.toDateParsed ?
          laycanStyleRange(request?.laycan?.fromDateParsed, request?.laycan?.toDateParsed) :
          (request?.laycan?.original ?? '')
      }
      showErrorMessage={showErrors}
      onFocus={():void => setIsParsing(true)}
    />
    <div className="form-input__container">
      <label htmlFor="spot-status">Status</label>
      <Dropdown
        id="spot-status"
        value={request?.status ?? StatusEnum.New}
        onChange={(e:DropdownChangeEvent):void => mutateCargo({ status: e.value })}
        options={statuses}
        optionLabel='key'
      />
    </div>
    {/* // Disabled Ability to Edit isUrgent
    <ToggleSwitch
      checked={request?.isUrgent ?? false}
      callback={(e: boolean):void => mutateCargo({ isUrgent: e })}
      label="right"
      align="left">
      Urgent
    </ToggleSwitch>
    */}
    <SingleEntitySearch
      customItems={[{
        value: obxuser?.name ?? '',
        searchEntityId: obxuser?.userId ?? '',
        template: AssignToMe
      }]}
      showCustomItem={!request.assignedUser || request.assignedUser.userName !== obxuser?.name}
      label='Assignee'
      isDisabled={request.confidentialityIndicator === PositionConfidentialityEnum.VPNC}
      module={EntitySearchGroupEnum.Users}
      fields={EntitySearchFieldsEnum.User}
      initialTerm={formatName(tempValue ?? request.assignedUser?.userName ?? '')}
      itemTemplate={(i: SuggestionResponse):string => formatName(i.value)}
      onInputClear={():void => mutateCargo({ assignedUser: undefined })}
      callback={(change?: SuggestionResponse):void => {
        setTempValue(' ');
        mutateCargo({ assignedUser: { userId: change?.searchEntityId ?? '', userName: change?.value ?? '' }});
      }}
    />
    <div className="form-input__container">
      <label htmlFor="spot-notes">Comments</label>
      <InputTextarea
        id="spot-notes"
        autoResize
        onFocus={(e):void => e.target.select()}
        value={request?.notes ?? ''}
        onChange={(e):void => mutateCargo({ notes: e.target.value })}
      />
    </div>
  </>;
};

export default CargoEditSpot;