import { useRef, RefObject } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';

import { humanDate } from 'helpers/Utils/formatters';
import { formatName } from 'helpers/Utils/string';
import GridElement from 'components/GridElement';
import SwipeableHiddenOptions from 'components/SwipeableHiddenOptions';

import type { ApiWorkflowResponse } from 'modules/Workflows/Models/ApiWorkflowResponse';

import './GridItemTemplate.scss';

interface GridItemTemplateProps {
  item: ApiWorkflowResponse;
  deleteWorkflow: (id: string) => Promise<void>;
  selectWorkflow: (item: ApiWorkflowResponse) => void;
  scrollableContainerRef: RefObject<HTMLElement>;
}

export default function GridItemTemplate(props: GridItemTemplateProps): JSX.Element {
  const { deleteWorkflow, item, scrollableContainerRef, selectWorkflow } = props;
  const elRef = useRef<HTMLDivElement>(null);

  return <SwipeableHiddenOptions
    key={item.id}
    className='workflows-page__grid-item-swipeable-options'
    elementRef={elRef}
    scrollableContainerRef={scrollableContainerRef}
    moveByPixels={80}
    options={<Button
      text
      className='workflow-page__delete-button-mobile no--background grow-to-fill'
      icon='iconoir-trash icon--primary'
      onClick={() => deleteWorkflow(item.id)}
      // required to prevent swiping back on option click
      onPointerDown={(e) => e.preventDefault()}
    />}
  >
    <div
      className='workflows-page__grid-item grow-to-fill'
      onClick={() => selectWorkflow(item)}
      ref={elRef}
    >
      <GridElement header='Title' body={item.title} />
      <GridElement header='Requested' body={<div className='direction--column'>
        <strong>{humanDate(item.createdUtc, { time: true })} GMT</strong>
        <div className='workflows-page__grid-item--requested-name'>{formatName(item.requestedByName)}</div>
      </div>} />
      <GridElement
        header='Progress'
        body={<>
          <ProgressBar
            key={item.id}
            className='grow-to-fill'
            showValue={false}
            value={item.inProgressCount * 100 / item.totalCount}
          />
          {item.inProgressCount}/{item.totalCount}
        </>}
      />
    </div>
  </SwipeableHiddenOptions>;
}
