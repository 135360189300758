import { useContext } from 'react';

import { WorkflowStatusResolverContext } from 'modules/Workflows/WorkflowPage';
import { WorkflowStatusTypeEnum } from 'modules/Workflows/Models/WorkflowStatusTypeEnum';

import './TaskHistoryInfoStatus.scss';

interface StatusProps {
  status: WorkflowStatusTypeEnum;
}

export default function Status(props: StatusProps): JSX.Element {
  const { status } = props;

  const workflowStatusResolverContext = useContext(WorkflowStatusResolverContext);
  const { resolveStatus } = workflowStatusResolverContext;

  return <div className={`task-history-info-status status--${WorkflowStatusTypeEnum[status]}`}>
    {typeof resolveStatus === 'function' ?
      resolveStatus(status) :
      WorkflowStatusTypeEnum[status]
    }
  </div>;
}