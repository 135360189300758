import useSWRMutation from 'swr/mutation';
import useSWR, { useSWRConfig, State } from "swr";
import { DateTime } from 'luxon';

import { RateGridApi } from './RateGridsAPI';
import { 
	ISignalRRateGridValue,
	RateGridResponse,
	IRateGridRouteValueResponse,
	RateGridRoute } from '../Models/RateGridResponse';
import { SignalRGridMutationType } from '../Models/Enums';

import { replaceItemAt } from 'helpers/Utils/collections';
import { formatName } from 'helpers/Utils/string';

interface ISaveNewRateHookResponse {
	updateRate: (param: any) => void,
	error: any,
	isMutating: boolean,
}


/**
 * Custom hook which returns an updateRate mutation method that passes
 * its parameters to the RateGridApi.setRategridValue method, saving
 * changes in value for a rate to the server.
 *
 * @returns {ISaveNewRateHookResponse} Response object with a refference to the remote trigger method, and a boolean isMutating value to indicate if the server is currently processing the value
 */
export const useSaveNewRate = (): ISaveNewRateHookResponse => {

	const { trigger, error, isMutating } = useSWRMutation<any>(
		'rate',
		RateGridApi.setRategridValue,
		{
			onSuccess: (d, g, e) => {
				//	TODO - currently the schema of the returned result
				//	is incorrect. Once this is updated (ticket #1069)
				//	we can use this to correctly mutate our local
				//	cache as we desire
			}
		}
	)

	return { updateRate: trigger, error, isMutating}
}

interface UseLoadGridResponse {
	gridData: any;
	error: any;
	isLoading: boolean;
	mutateData: Function;
}


/**
 * Custom hook which returns all the routes, markers and prices for a passed
 * grid reference.
 *
 * @param {string} grid
 * @returns {UseLoadGridResponse}
 */
export const useLoadRateGrid = (grid: string): UseLoadGridResponse => {
	
	const key: string = `rate-grid-${grid}`
	const { cache } = useSWRConfig();
	const { data, error, isLoading, mutate } = useSWR(
		key,
		() => RateGridApi.getRateGrid(grid),
		{
			revalidateOnFocus: false
		}
	)

	const mutateData = (type: any, mutation: ISignalRRateGridValue) => {

		//	TODO - adaptable ,utation based on type
		// console.log("type is", type, "on grid", mutation.linkedRateGridId);

		const { data: current } = cache.get(`rate-grid-${mutation.linkedRateGridId}`) as State<RateGridResponse>;
		
		if (!current) return;

		switch(type) {
			case SignalRGridMutationType.CellValue:
				//	Mutation is just for a single cell
				const routes = current.routes.reduce(
					(acc, curr) => {
						
						if (curr.name === mutation.route) {
							//	this is the route with the updated value
							const { values } = curr;
				
							let index: number = values.findIndex(v => v.columnGroup === mutation.columnGroup && v.columnName === mutation.columnName);
							let result = values[index];
							
							const { asOf, valueSetByDisplayName } = mutation;
							const parsed = {
								...mutation,
								asOf: DateTime.fromISO(asOf, { zone: 'utc'}),
								valueSetByDisplayName: formatName(valueSetByDisplayName)
							}
		
							curr = {...curr, values: replaceItemAt(values, {...result, ...parsed}, index)}
						}
		
						return [...acc, curr];
					},
					[] as RateGridRoute<IRateGridRouteValueResponse>[]
				)
				mutate({...current, routes}, { revalidate: false });
				break;
			case SignalRGridMutationType.FullGrid:
				//	TODO - handle full grid changing (see #1069)
				break;
		}
	}

	return { gridData: data, error, isLoading, mutateData }
}
