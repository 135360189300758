import { useContext, useMemo } from 'react';
import { WorkflowStatusResolverContext } from '../WorkflowPage';
import { WorkflowStatusTypeEnum } from './WorkflowStatusTypeEnum';
import { getValueCollection } from 'helpers/Utils/enum';

type WorkflowTaskStatusOption = ({
  value: WorkflowStatusTypeEnum;
  label: string | undefined;
} | {
  value: string;
  label: string;
});

export function useStatusOptions(includeCustom?: boolean): WorkflowTaskStatusOption[] {
  const workflowStatusResolverContext = useContext(WorkflowStatusResolverContext);
  const { resolveStatus } = workflowStatusResolverContext;

  return useMemo(() => {
    if (!resolveStatus) {
      return [];
    }

    const o: WorkflowTaskStatusOption[] = getValueCollection(WorkflowStatusTypeEnum)
      .reduce((acc, item) =>
        // `getValueCollection` returns numeric reverse mapping for numeric enums so literals must be filtered out
        typeof item.key === 'number' ?
          [...acc, {
            value: item.key,
            label: resolveStatus(item.key)
          }] :
          acc,
        [] as WorkflowTaskStatusOption[]);

    if (includeCustom) {
      o.push({ value: 'Custom', label: 'Change status with note' });
    }

    return o;
  }, [includeCustom, resolveStatus]);
}