import Joi from "joi";

import { PHONE_REGEX } from 'helpers/Utils/string';

import { COMISSION_RATE_OPTIONS, COMMISSION_RATE_FIELD, COMMISSION_RATE_VALUE_FIELD, CommissionRateType } from './CommissionRate';

const MESSAGES = {
    'string.empty': 'Required field',
    'string.pattern.base': 'Invalid format',
    'string.email': 'Invalid format',
    'string.min': 'Field must have min {#limit} characters',
    'string.max': 'Field must have max {#limit} characters',
	'any.only': 'Required field'
};

export const commissionRateValidator = Joi.object({
	[COMMISSION_RATE_FIELD]: Joi.string().valid(...COMISSION_RATE_OPTIONS).required().messages(MESSAGES),
	[COMMISSION_RATE_VALUE_FIELD]: Joi.when(COMMISSION_RATE_FIELD, {
		is: CommissionRateType.Custom,
		then: Joi.string().required().max(10).messages(MESSAGES),
		otherwise: Joi.optional().allow('').messages(MESSAGES)
	})
});

export const templateValidator = Joi.object({
	Template: Joi.string().required().messages(MESSAGES),
}).unknown(true);

// TODO: update fields that are required. Remove commented out code or un-comment it if needed.
export const newWorkflowValidator = Joi.object({
	'Broker Desk': Joi.string().allow('')/* .required() */.messages(MESSAGES),
	'Counterparty Name': Joi.string().required().min(3).messages(MESSAGES),
	'Counterparty Contact': Joi.string().allow('')/* .required() */.min(3).messages(MESSAGES),
	'Counterparty Email': Joi.string().email({ tlds: { allow: false } }).allow('')/* .required() */.messages(MESSAGES),
	'Counterparty Phone Number': Joi.string().allow('')/* .required() */.pattern(PHONE_REGEX).messages(MESSAGES),
	'Notes': Joi.string().optional().allow(''),
}).unknown(true).concat(commissionRateValidator);