import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import { DistributionListCreateRequest } from '../../Models/distribution-list-create-request';

import { removeItemAt, replaceItemAt } from 'helpers/Utils/collections';
import { stringToEmailAddresses } from 'helpers/Utils/string';

interface ListEditEmailProps {
  email: string;
  index: number;
  request: DistributionListCreateRequest;
  mutateList: (mutation: Partial<DistributionListCreateRequest>) => void;
  setIsEmailError: Dispatch<SetStateAction<boolean>>;
}

const ListEditEmail = (props: ListEditEmailProps):JSX.Element => {
  const { email, index, request, mutateList, setIsEmailError } = props;
  const [ value, setValue ] = useState<string>(email);
  const [ isError, setIsError ] = useState<boolean>(false);

  const removeEmail = useCallback((email: string):void => {
    const index = request.recipients.indexOf(email);
    mutateList({ recipients: removeItemAt(request.recipients, index) });
  }, [mutateList, request.recipients]);

  return (
    <div className={clsx('dl-email-table-item-wrapper', {'p-invalid': isError || value.trim() === ''})}>
      <div className={clsx('dl-email-table-item p-inputgroup flex-1', {'p-invalid': isError || value.trim() === ''})}>
        <InputText
          className="dl-email-table-item__input"
          onChange={(e): void => {
            setValue(e.target.value);
            const {passed} = stringToEmailAddresses(e.target.value);

            if (passed.length) {
              mutateList({
                recipients: replaceItemAt(request.recipients, passed[0], index)
              });
              setIsEmailError(false);
              setIsError(false);
            } else {
              setIsEmailError(true);
              setIsError(true);
            }
          }
          }
          value={value ?? ''}
        />
        <Button
          text
          size="small"
          type="button"
          icon="iconoir-trash icon--tiny icon--right"
          onClick={(): void => removeEmail(email)}
        />
      </div>
      {isError && <small className="message-invalid">Please enter a valid email</small>}
    </div>
  );};

export default ListEditEmail;