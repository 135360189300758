import { RefObject, useLayoutEffect, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

type ContainerContentBox = {
	width: number,
	scrollWidth: number,
}

export const useDimensions = (target: RefObject<HTMLElement>): ContainerContentBox => {
	const [ dimensions, setDimensions ] = useState<ContainerContentBox>();

	useLayoutEffect(() => {

		if (target.current) {
			const { offsetWidth: width, scrollWidth } = target.current;
			setDimensions({width, scrollWidth});
		}

	}, [target]);

	useResizeObserver(target.current, (e: ResizeObserverEntry) => {
		console.log("ro");
		const { offsetWidth: width, scrollWidth } = e.target as HTMLDivElement;
		setDimensions({width, scrollWidth});
	})

	return {width: dimensions?.width ?? 0, scrollWidth: dimensions?.scrollWidth ?? 0}
}

export default useDimensions;