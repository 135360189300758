import { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useSwipeable } from 'react-swipeable';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { DataTable, type DataTableProps, type DataTableRowClickEvent, type DataTableSortEvent } from 'primereact/datatable';
import { DataView } from 'primereact/dataview';
import { Column, type ColumnSortEvent } from 'primereact/column';
import { ProgressBar } from 'primereact/progressbar';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useDebounce } from 'primereact/hooks';
import { clsx } from 'clsx';

import { useSignalR } from 'App';
import { UISettings } from 'components/OBXUser/Model/Enums';
import SecondaryNavigation from 'components/SecondaryNavigation';
import ToastMessage, { type ToastMessageRef, ToastSeverity } from 'components/ToastMessage';
import AdditionalFilters from 'components/AdditionalFilters';
import BorealisBar from 'components/BorealisBar';
import { useSaveUserSetting, useLoadUserSettings } from 'components/OBXUser/Services/ProfileHooks';
import eventBus from 'server/EventBus';
import { replaceItemAt } from 'helpers/Utils/collections';
import { getByNumber, getValueCollection } from 'helpers/Utils/enum';
import { sortByDateTime, sortByNumerical } from 'helpers/DataTable/SortingFunctions';
import { DoubleLineUpdatedAuthorAndDate } from 'helpers/DataTable/Templates/ColumnTemplates';

import WorkflowDetails from './Components/WorkflowDetails';
import CreateWorkflow from './Components/CreateWorkflow';
import { WorkflowProvider } from './Models/WorkflowProvider';
import { WorkflowStatusTypeEnum } from './Models/WorkflowStatusTypeEnum';
import {
    type CommissionRate,
    useAddTaskUpdate,
    useDeleteWorkflow,
    useExportWorkflow,
    useRenameWorkflow,
    useSearchWorkflows,
    useStartWorkflow,
    useUpdateCommissionRate,
    WorkflowApi
} from './Services/WorkflowApi';
import { WorkflowSignalEventTypes, WorkflowSocket } from './Services/SignalRSocket';
import DeleteWorkflowDialogFooter from './Templates/DeleteDialog';
import GridItemTemplate from './Templates/GridItemTemplate';
import WorkflowEmptyPage, { EmptyPageMode } from './Templates/EmptyPage';
import NoWorkflowSelected from './Templates/NoWorkflowSelected';

import type { ApiWorkflowResponse } from './Models/ApiWorkflowResponse';
import type { ApiWorkflowTaskResponse } from './Models/ApiWorkflowTaskResponse';
import type { ApiWorkflowPropertyKvp } from './Models/ApiWorkflowPropertyKvp';

import './WorkflowPage.scss';

export const WorkflowStatusResolverContext = createContext<{ resolveStatus?: (arg: number) => string; }>({ resolveStatus: undefined });

type SortConfig = {
    sortField: DataTableProps<ApiWorkflowResponse[]>['sortField'];
    sortOrder: DataTableProps<ApiWorkflowResponse[]>['sortOrder'];
};

type WorkflowsSettings = SortConfig & {
    searchTerm: string;
    lastOpenStatus: string;
    justMyTasks: boolean;
    justMyWorkflows: boolean;
};

enum Mode {
    None = 0,
    Create,
    Edit,
    NoWorkflowSelected
}

export default function WorkflowPage() {
    const { trigger: saveSetting } = useSaveUserSetting();
    const { getSetting } = useLoadUserSettings();

    const settings: WorkflowsSettings | undefined = getSetting(UISettings.WORKFLOWS_CONFIG);

    const { providerId, statusFilter } = useParams();
    const [searchTerm, searchTermDebounced, setSearchTerm] = useDebounce(settings?.searchTerm ?? '', 500) as [string, string, (arg?: string) => void];
    const [workflows, setWorkflows] = useState<ApiWorkflowResponse[]>([]);
    const [selectedWorkflow, setSelectedWorkflow] = useState<ApiWorkflowResponse | null>(null);
    const [mode, setMode] = useState<Mode>(Mode.None);
    const [justMyWorkflows, setJustMyWorkflows] = useState<boolean>(settings?.justMyWorkflows ?? false);
    const [justMyTasks, setJustMyTasks] = useState<boolean>(settings?.justMyTasks ?? false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
    const [workflowIdToDelete, setWorkflowIdToDelete] = useState<string | null>(null);
    const toast = useRef<ToastMessageRef>(null);
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
    const containerRef = useRef<HTMLDivElement>(null);

    const gestures = useSwipeable({
        onSwipedRight: (): void => {
            unselectWorkflow();
        },
    });

    const initialSort: SortConfig = useMemo(() => ({
        sortField: settings?.sortField ?? 'createdUtc,requestedByName',
        sortOrder: settings?.sortOrder ?? -1
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);
    // store config in state to react immidiatelly on sort config change
    const [sortConfig, setSortConfig] = useState<SortConfig>(initialSort);

    const { signal } = useSignalR();

    useEffect(() => {
        if (!providerId && !statusFilter) {
            navigate(`/workflows/${WorkflowProvider.Onboarding}/${settings?.lastOpenStatus ?? WorkflowStatusTypeEnum.NotStarted}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerId, settings?.lastOpenStatus, statusFilter]);

    const getProviderId = () => {
        if (!providerId) {
            return WorkflowProvider.Onboarding;
        }
        return parseInt(providerId);
    };

    const { data: workflowResults, isLoading: workflowsLoading, isValidating: workflowsValidating, mutate: getWorkflows } = useSearchWorkflows({
        workflowProvider: getProviderId(),
        searchTerm: searchTerm ?? '',
        justMyWorkflows,
        justMyTasks
    });
    const { addTaskUpdate, isMutating: addTaskUpdateIsMutating } = useAddTaskUpdate();
    const { startWorkflow, isMutating: startWorkflowIsMutating } = useStartWorkflow();
    const { deleteWorkflow, isMutating: deleteWorkflowIsMutating } = useDeleteWorkflow();
    const { exportWorkflow, isMutating: exportWorkflowIsMutating } = useExportWorkflow();
    const { renameWorkflow, isMutating: renameWorkflowIsMutating } = useRenameWorkflow();
    const { updateCommissionRate, isMutating: updateCommissionRateIsMutating } = useUpdateCommissionRate();

    useEffect(() => {
        if (workflowResults) {
            const filteredResults = workflowResults.filter(x => x.overallStatus === parseInt(statusFilter || '0'));

            setWorkflows(filteredResults);

            if (!filteredResults.length && mode !== Mode.Create) {
                unselectWorkflow();
            }
        } else {
            setWorkflows([]);
        }
    }, [mode, statusFilter, workflowResults]);

    useEffect(() => {
        setIsLoading(workflowsLoading || workflowsValidating || addTaskUpdateIsMutating || startWorkflowIsMutating || deleteWorkflowIsMutating || updateCommissionRateIsMutating);
    }, [workflowsLoading, workflowsValidating, addTaskUpdateIsMutating, startWorkflowIsMutating, deleteWorkflowIsMutating, updateCommissionRateIsMutating]);

    useEffect(() => {
        saveSetting({
            setting: UISettings.WORKFLOWS_CONFIG,
            data: {
                sortField: sortConfig.sortField,
                sortOrder: sortConfig.sortOrder,
                justMyTasks,
                justMyWorkflows,
                lastOpenStatus: statusFilter,
                searchTerm: searchTermDebounced // use debounced value to limit `saveSatting` calls
            } as WorkflowsSettings
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [justMyTasks, justMyWorkflows, searchTermDebounced, sortConfig, statusFilter]);

    useEffect(() => {
        // re-fetch Workflows data when any param changes
        getWorkflows();
    }, [searchTerm, justMyTasks, justMyWorkflows, getWorkflows]);

    useEffect(() => {

        let socket: WorkflowSocket;
        socket = WorkflowSocket.instance;
        socket.init(signal);

    }, [signal]);

    useEffect(() => {

        eventBus.on(
            WorkflowSignalEventTypes.WORKFLOW_CHANGED,
            handleSignalRUpdate
        );

        return () => {
            eventBus.remove(
                WorkflowSignalEventTypes.WORKFLOW_CHANGED,
                handleSignalRUpdate
            );
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSignalRUpdate = (data: CustomEvent<ApiWorkflowResponse>) => {
        if (!data.detail.isDeleted) {
            setWorkflows((workflows) => {
                const index = workflows.findIndex(x => x.id === data.detail.id);

                if (index > -1) {
                    return replaceItemAt(workflows, WorkflowApi.parseWorkflow(data.detail), index);
                }

                return workflows;
            });
        }
    };

    const handleSortChange = ({ sortField, sortOrder }: DataTableSortEvent): void => {
        setSortConfig({ sortField, sortOrder });
    };

    const hideDialog = () => {
        setIsDialogVisible(false);
        setWorkflowIdToDelete(null);
    };

    const handleDeleteWorkflowClick = (wf: ApiWorkflowResponse): void => {
        setWorkflowIdToDelete(wf.id);
        setIsDialogVisible(true);
    };

    const handleDeleteWorkflow = async (workflowId: string | null) => {
        if (workflowId) {
            try {
                await deleteWorkflow({ workflowId });

                if (selectedWorkflow?.id === workflowId) {
                    unselectWorkflow();
                }
            } catch (err) {
                toast.current?.replace({
                    title: 'Issue occurred',
                    message: 'Issue with workflow deletion',
                    severity: ToastSeverity.WARN
                });
            }
        }

        hideDialog();
    };

    const handleExport = async (workflow: ApiWorkflowResponse): Promise<void> => {
        try {
            const { id, title } = workflow;
            const response = await exportWorkflow({ workflowId: id, workflowName: title });

            if (response) {
                toast.current?.replace({
                    title: 'Success',
                    message: 'Workflow exported',
                    severity: ToastSeverity.SUCCESS
                });
            }
        } catch (err) {
            toast.current?.replace({
                title: 'Issue occurred',
                message: 'Issue with workflow exporting',
                severity: ToastSeverity.WARN
            });
        }
    };

    const handleRename = async (wf: ApiWorkflowResponse, value: string): Promise<void> => {
        try {
            const response = await renameWorkflow({ newWorkflowName: value, workflowId: wf.id });

            if (response) {
                selectWorkflow(response);
            };
        } catch (err) {
            toast.current?.replace({
                title: 'Issue occurred',
                message: 'Issue with renaming of the workflow',
                severity: ToastSeverity.WARN
            });
        }
    };

    const handleUpdateCommissionRate = async (wf: ApiWorkflowResponse, rate: CommissionRate): Promise<void> => {
        const { type, value } = rate;

        try {
            const response = await updateCommissionRate({ type, value, workflowId: wf.id });

            if (response) {
                selectWorkflow(response);
            };
        } catch (err) {
            toast.current?.replace({
                title: 'Issue occurred',
                message: 'Issue with updating of the workflow',
                severity: ToastSeverity.WARN
            });
        }
    };

    const updateTask = async (task: ApiWorkflowTaskResponse, comment?: string): Promise<void> => {
        if (selectedWorkflow) {
            try {
                const workflow = await addTaskUpdate({
                    provider: getProviderId(),
                    task,
                    comment,
                    workflowId: selectedWorkflow.id,
                });

                if (workflow) {
                    // update tab on workflow update
                    navigate(`/workflows/${providerId}/${workflow.overallStatus}`);
                    selectWorkflow(workflow);
                }
            } catch (err) {
                toast.current?.replace({
                    title: 'Issue occurred',
                    message: 'Issue with updating of the workflow',
                    severity: ToastSeverity.WARN
                });
            }
        }
    };

    const handleCreateWorkflow = async (templateName: string, properties: ApiWorkflowPropertyKvp<string, string>[] = []): Promise<void> => {
        try {
            const workflow = await startWorkflow({ templateName, properties });

            if (workflow) {

                toast.current?.replace({
                    title: 'Workflow Added',
                    message: 'A new workflow has been successfully added',
                    severity: ToastSeverity.SUCCESS
                });

                // show newly created workflow details
                selectWorkflow(workflow);
                // update tab (in case that another tab was open and newly created workflow has overallStatus === 'NotStarted')
                navigate(`/workflows/${providerId}/${workflow.overallStatus}`);
            }
        } catch (e) {
            // Handled by ErrorToastService
        }
    };

    const selectWorkflow = (workflow: ApiWorkflowResponse | null): void => {
        setSelectedWorkflow(workflow);
        setMode(Mode.Edit);
    };

    const unselectWorkflow = (): void => {
        setSelectedWorkflow(null);
        setMode(Mode.None);
    };

    const headerContent = useMemo(() => {
        let content = <>
            <InputText placeholder='Search by keywords' value={searchTerm} onChange={(e) => setSearchTerm(e.currentTarget.value)} className='search-input' />
            <AdditionalFilters
                className='workflow__additional-filter'
                filterCount={Number(justMyTasks) + Number(justMyWorkflows) || undefined}
            >
                <div className='filter-item'>
                    <Checkbox checked={justMyWorkflows} onChange={(e => setJustMyWorkflows(!!e.checked))} />
                    <small>My Workflows</small>
                </div>
                <div className='filter-item'>
                    <Checkbox checked={justMyTasks} onChange={(e => setJustMyTasks(!!e.checked))} />
                    <small>My Outstanding Tasks</small>
                </div>
            </AdditionalFilters>
        </>;

        if (isMobile) {
            if (mode !== Mode.None) {
                return null; // no header if aside is open
            }
            return <Accordion activeIndex={0}>
                <AccordionTab header='Search'>
                    {content}
                </AccordionTab>
            </Accordion>;
        }

        return content;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile, justMyTasks, justMyWorkflows, mode, searchTerm]);

    const workflowStatusResolver = (status: WorkflowStatusTypeEnum) => {
        const statusName = getByNumber<typeof WorkflowStatusTypeEnum>(WorkflowStatusTypeEnum, status, true);

        if (status === WorkflowStatusTypeEnum.WaitingOnRequestor ||
            status === WorkflowStatusTypeEnum.WaitingForCustomer) {
            // lowercase the prepositions ('on', 'for')
            const [first, second, ...rest] = statusName.split(' ');
            return `${first} ${second.toLowerCase()} ${rest}`;
        }

        return statusName;
    };

    const handleTabChange = (): Promise<void> => {
        if (mode === Mode.Edit) {
            setSelectedWorkflow(null);
            setMode(Mode.NoWorkflowSelected);
        }

        // required by SecondaryNavigation component
        return Promise.resolve();
    }

    const navigationItems = useMemo(() =>
        getValueCollection(WorkflowStatusTypeEnum)
            .reduce((acc, item) =>
                // `getValueCollection` returns numeric reverse mapping for numeric enums so literals must be filtered out
                typeof item.key === 'number' ?
                    [...acc, {
                        path: `${providerId}/${item.key}`,
                        label: `${workflowStatusResolver(item.key)} (${workflowResults?.filter(x => x.overallStatus === +item.key).length ?? 0})`
                    }] :
                    acc,
                [] as { path: string; label: string; }[]),
        [providerId, workflowResults]
    );

    const drawerActive = useMemo(() => mode !== Mode.None, [mode]);

    return <div ref={containerRef} className='workflow-page direction--column grow-to-fill overflow--y'>
        {!(drawerActive && isMobile) && <div className='add-workflow__button-container'>
            <Button
                className='add-workflow__button'
                size='small'
                onClick={() => {
                    setMode(Mode.Create);
                    // if something selected, unselect it
                    setSelectedWorkflow(null);
                }}
            >
                Add workflow
            </Button>
        </div>}
        <header>
            {headerContent}
        </header>
        {!(drawerActive && isMobile) && <nav className='tabbed-navigation-set__container'>
            <SecondaryNavigation items={navigationItems} onBeforeNavigation={handleTabChange} />
        </nav>}
        <main
            className={clsx('grow-to-fill workflow-page__main',
                { 'drawer--active': drawerActive }
            )}
            data-cols={drawerActive ? '4,8' : '12,0'}
            data-drawer-style='slide'
            data-drawer-position='alongside-right'
        >
            {!isLoading && !searchTerm && (!workflowResults?.length || !workflows.length) ?
                <section>
                    <WorkflowEmptyPage
                        mode={!workflowResults?.length && !(justMyTasks || justMyWorkflows) ? EmptyPageMode.EmptyResults : EmptyPageMode.EmptyStatus}
                        onAddWorkflowButtonClick={() => setMode(Mode.Create)}
                    />
                </section> :
                <section className='grow-to-fill overflow--hidden'>
                    {isMobile ?
                    <DataView
                        gutter
                        className='workflows-page__data-view'
                        itemTemplate={(item) => <GridItemTemplate
                            deleteWorkflow={handleDeleteWorkflow}
                            item={item}
                            scrollableContainerRef={containerRef}
                            selectWorkflow={selectWorkflow}
                        />}
                        loading={isLoading}
                        loadingIcon={<BorealisBar />}
                        value={workflows}
                    /> :
                    <DataTable
                        className={clsx(drawerActive && 'columns-hidden')}
                        emptyMessage={searchTerm ? 'Sorry, no results match that term' : undefined}
                        loading={isLoading}
                        onRowClick={(e: DataTableRowClickEvent) => selectWorkflow(e.data as ApiWorkflowResponse)}
                        rowClassName={(data: ApiWorkflowResponse) => data.id === selectedWorkflow?.id ? 'p-selected-row' : ''}
                        scrollable
                        scrollHeight='flex'
                        {...sortConfig}
                        onSort={handleSortChange}
                        value={workflows}
                    >
                        <Column
                            field='title'
                            header='Title'
                            sortable
                        />
                        <Column
                            field='inProgressCount'
                            header='Progress'
                            body={(data: ApiWorkflowResponse) => <>
                                <ProgressBar
                                    key={data.id}
                                    showValue={false}
                                    value={data.inProgressCount * 100 / data.totalCount}
                                />
                                {data.inProgressCount}/{data.totalCount}
                            </>}
                            sortable
                            sortFunction={(e) => sortByNumerical(e, 'inProgressCount')}
                        />
                        <Column
                            field='createdUtc,requestedByName'
                            header='Requested'
                            body={DoubleLineUpdatedAuthorAndDate}
                            sortable
                            sortFunction={(e: ColumnSortEvent): ReturnType<typeof sortByDateTime> => sortByDateTime(e, 'createdUtc')}
                        />
                        <Column
                            headerClassName={clsx(drawerActive && 'hidden')} // somehow 'hidden' className is not applied on prod - have to add it manually
                            className={clsx(drawerActive && 'hidden')}
                            field='lastModified,lastModifiedUserName'
                            header='Updated'
                            body={DoubleLineUpdatedAuthorAndDate}
                            sortable
                            sortFunction={(e: ColumnSortEvent): ReturnType<typeof sortByDateTime> => sortByDateTime(e, 'lastModified')}
                        />
                        <Column
                            body={(data: ApiWorkflowResponse) => <Button
                                text size='small'
                                icon='iconoir-trash icon--small icon--right'
                                className='no--background'
                                onClick={() => {
                                    setWorkflowIdToDelete(data.id);
                                    setIsDialogVisible(true);
                                }}
                            />}
                        />
                    </DataTable>}
                    <Dialog
                        className='delete-workflow__dialog p-dialog--margin-less'
                        dismissableMask // hide Dialog on backdrop click
                        header='Delete workflow'
                        footer={<DeleteWorkflowDialogFooter
                            deleteWorkflow={() => handleDeleteWorkflow(workflowIdToDelete)}
                            hideDialog={hideDialog}
                            isMutating={deleteWorkflowIsMutating}
                        />}
                        closeIcon={<i className='icon--small iconoir-xmark-circle' />}
                        onHide={hideDialog}
                        position='top'
                        visible={isDialogVisible}
                        resizable={false}
                        draggable={false}
                    >
                        <p>Are you sure you want to permanently delete this workflow?</p>
                        <p>You can't undo this.</p>
                    </Dialog>
                </section>}
                <aside {...gestures}>
                    {drawerActive && <WorkflowStatusResolverContext.Provider value={{ resolveStatus: workflowStatusResolver }}>
                        {mode === Mode.Create && <CreateWorkflow
                            getProviderId={getProviderId}
                            isLoading={startWorkflowIsMutating}
                            startWorkflow={handleCreateWorkflow}
                            closePanel={unselectWorkflow}
                        />}
                        {mode === Mode.Edit && selectedWorkflow && <WorkflowDetails
                            updateTask={updateTask}
                            isUpdatingTask={addTaskUpdateIsMutating}
                            exportWorkflow={handleExport}
                            isExporting={exportWorkflowIsMutating}
                            // no Dialog on mobile -> call deleteWorkflow directly
                            deleteWorkflow={isMobile ? (wf) => handleDeleteWorkflow(wf.id) : handleDeleteWorkflowClick}
                            renameWorkflow={handleRename}
                            isRenaming={renameWorkflowIsMutating}
                            workflow={selectedWorkflow}
                            updateCommissionRate={handleUpdateCommissionRate}
                            isUpdatingCommissionRate={updateCommissionRateIsMutating}
                            closePanel={unselectWorkflow}
                        />}
                    {mode === Mode.NoWorkflowSelected && <NoWorkflowSelected
                        closePanel={unselectWorkflow}
                    />}
                    </WorkflowStatusResolverContext.Provider>}
                </aside>
        </main>
        <ToastMessage ref={toast} />
    </div>;
}