import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTableDataSelectableEvent, DataTableRowClickEvent, DataTableSelectionSingleChangeEvent, type DataTableRowToggleEvent } from 'primereact/datatable';
import { Dropdown, type DropdownChangeEvent } from 'primereact/dropdown';
import { Accordion, AccordionTab } from 'primereact/accordion';
import clsx from 'clsx';

import { COMMISSION_RATE_FIELD, COMMISSION_RATE_VALUE_FIELD } from 'modules/Workflows/Models/CommissionRate';
import { WorkflowStatusResolverContext } from 'modules/Workflows/WorkflowPage';
import Task from 'modules/Workflows/Templates/Task';
import WorkflowName from 'modules/Workflows/Templates/WorkflowName';
import CommissionRateField from 'modules/Workflows/Components/CommissionRateField';
import TaskSidebar from 'modules/Workflows/Templates/TaskSidebar';
import TaskHistoryInfoStatus from 'modules/Workflows/Templates/TaskHistoryInfoStatus';
import FilterOption, { type FilterOptionValue } from 'modules/Workflows/Templates/WorkflowDetailsFilterOption';
import { useStatusOptions } from 'modules/Workflows/Models/hooks';
import { WorkflowStatusTypeEnum } from 'modules/Workflows/Models/WorkflowStatusTypeEnum';
import { TaskChangeType } from 'modules/Workflows/Models/ApiWorkflowTaskResponse';
import { formatName } from 'helpers/Utils/string';
import NotAvailable from 'components/NotAvailable';
import SpecDetails from 'components/SpecDetails';

import type { CommissionRate } from 'modules/Workflows/Services/WorkflowApi';
import type { ApiWorkflowResponse } from 'modules/Workflows/Models/ApiWorkflowResponse';
import type { ApiWorkflowTaskResponse, StatusTimeStamp } from 'modules/Workflows/Models/ApiWorkflowTaskResponse';
import type { SuggestionResponse } from 'modules/CargoTracker/Components/GroupedSearch';

import './WorkflowDetails.scss';

type TaskHistoryObject = Omit<ApiWorkflowTaskResponse, 'statusTimeStamps'> & StatusTimeStamp;

interface WorkflowDetailsProps {
  workflow: ApiWorkflowResponse;
  exportWorkflow: (arg: ApiWorkflowResponse) => Promise<void>;
  isExporting: boolean;
  deleteWorkflow: (arg: ApiWorkflowResponse) => void;
  renameWorkflow: (wf: ApiWorkflowResponse, value: string) => Promise<void>;
  isRenaming: boolean;
  updateCommissionRate: (wf: ApiWorkflowResponse, rate: CommissionRate) => Promise<void>;
  isUpdatingCommissionRate: boolean;
  updateTask: (task: ApiWorkflowTaskResponse, comment?: string) => Promise<void>;
  isUpdatingTask: boolean;
  closePanel: () => void;
}

export default function WorkflowDetails(props: WorkflowDetailsProps): JSX.Element {
  const {
    workflow,
    exportWorkflow,
    isExporting,
    deleteWorkflow,
    updateTask,
    isUpdatingTask,
    renameWorkflow,
    isRenaming,
    updateCommissionRate,
    isUpdatingCommissionRate,
    closePanel
  } = props;

  const [selectedAssignees, setSelectedAssignees] = useState<FilterOptionValue[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<FilterOptionValue[]>([]);

  const statusOptions = useStatusOptions();

  // all assignees from all workflow's tasks
  const assigneeOptions = useMemo(() => workflow.tasks.reduce(
    (acc, curr) => acc.findIndex(assignee => assignee.value === curr.assignedToName) > -1 ?
      acc :
      [...acc, { label: formatName(curr.assignedToName), value: curr.assignedToName }],
    [] as FilterOptionValue[]
  ), [workflow.tasks]);

  // expandedRows === the array of first elements of each group (row)
  const initialExpandedRow = useMemo(() => workflow.tasks.reduce(
    (acc, curr) => acc.findIndex(i => i.grouping === curr.grouping) > -1 ? acc : [...acc, curr],
    [] as ApiWorkflowTaskResponse[]
  ), [workflow]);

  const workflowStatusResolverContext = useContext(WorkflowStatusResolverContext);
  const { resolveStatus } = workflowStatusResolverContext;

  const [expandedRows, setExpandedRows] = useState<ApiWorkflowTaskResponse[]>(initialExpandedRow ?? []);
  const [selectedTab, setSelectedTab] = useState<'progress' | 'info'>('progress');
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const [selectedTask, setSelectedTask] = useState<ApiWorkflowTaskResponse | null>(null);
  // keep selected row selected even if task data has been updated
  const [selectedRowData, setSelectedRowData] = useState<ApiWorkflowTaskResponse | null>(null);

  const toggleTaskSelection = (data: ApiWorkflowTaskResponse | null) => {
    setSelectedTask(data);
    setSelectedRowData(data);
  };

  useEffect(() => {
    setSidebarVisible(!!selectedRowData);
  }, [selectedRowData]);

  useEffect(() => {
    // unselect task on workflow change
    toggleTaskSelection(null);
  }, [workflow])

  const toggleRow = (e: DataTableRowToggleEvent): void => {
    setExpandedRows(e.data as ApiWorkflowTaskResponse[]);
  };

  const filteredTasks = useMemo(() => {
    let tasks = workflow.tasks;

    if (selectedAssignees.length > 0) {
      tasks = tasks.filter(t => selectedAssignees.some(s => s.value === t.assignedToName));
    }

    if (selectedStatuses.length > 0) {
      tasks = tasks.filter(t => selectedStatuses.some(s => s.value === t.currentStatus));
    }

    return tasks;
  }, [selectedAssignees, selectedStatuses, workflow.tasks]);

  const handleStatusChange = (e: DropdownChangeEvent, task: ApiWorkflowTaskResponse, save?: boolean): void => {
    if (e.value === 'Custom') {
      toggleTaskSelection(task);

      return;
    }

    const updatedTask = { ...task, currentStatus: e.value };
    setSelectedTask(updatedTask);

    if (save) {
      updateTask(updatedTask);
    }
  };

  const handleAssigneeChange = (change: SuggestionResponse | null, task: ApiWorkflowTaskResponse, save?: boolean): void => {
    const newTask = { ...task, assignedToId: change?.searchEntityId ?? '', assignedToName: change?.value ?? '' };
    setSelectedTask(newTask);

    if (save) {
      updateTask(newTask);
    }
  }

  const generateTaskHistoryTitle = (item: TaskHistoryObject): ReactNode => {
    let title = <h4>{item.title}</h4>;
    let subtitle = null;

    switch (item.changeType) {
      case TaskChangeType.Assignee:
        subtitle = <small>Changed assignee to {formatName(item.assigneeName)} </small>;
        break;
      case TaskChangeType.Status:
      default:
        subtitle = <small>Changed status to "{resolveStatus?.(item.key)}" </small>;
        break;
    }

    return <>
      {title}
      {subtitle}
    </>;
  };

  const generateTaskHistorySpec = (item: TaskHistoryObject): Map<string, ReactNode> => {
    const { assigneeName, changeType, grouping, key, note, previousAssigneeName, previousStatus, updatedByUserName } = item;

    switch (changeType) {
      case TaskChangeType.Assignee:
        return new Map<string, ReactNode>([
          ['Previous assignee', formatName(previousAssigneeName!)],
          ['New assignee', formatName(assigneeName)],
          ['Updated by', formatName(updatedByUserName) || <NotAvailable />],
          ['Group', grouping],
          ['Notes', note || <NotAvailable label='Have not been added' />],
        ]);
      case TaskChangeType.Status:
      default:
        return new Map<string, ReactNode>([
          ['Previous status', <TaskHistoryInfoStatus status={previousStatus ?? WorkflowStatusTypeEnum.NotStarted} />],
          ['New status', <TaskHistoryInfoStatus status={key} />],
          ['Updated by', formatName(updatedByUserName) || <NotAvailable />],
          ['Group', grouping],
          ['Notes', note || <NotAvailable label='Have not been added' />],
        ]);
    }
  }

  const taskSpec = useMemo(() => new Map<string, ReactNode>([
    ['', <WorkflowName
      handleRename={renameWorkflow}
      isRenaming={isRenaming}
      workflow={workflow}
    />],
    ['Requested By', workflow.requestedByName],
    ...workflow.properties
      // 'Commission Rate Value' is displayed under 'Commission Rate' label. Omit this key.
      .filter(property => property.key !== COMMISSION_RATE_VALUE_FIELD && property.key !== 'Notes')
      .map((property) => {
        if (property.key === COMMISSION_RATE_FIELD) {
          return [property.key, <CommissionRateField
            isLoading={isUpdatingCommissionRate}
            updateRate={updateCommissionRate}
            workflow={workflow}
          />] as [string, ReactNode];
        }
        return [property.key, property.value] as [string, ReactNode];
      }),
    ['Notes', workflow.properties.find(p => p.key === 'Notes')?.value ?? '']
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ]), [isRenaming, isUpdatingCommissionRate, workflow]);


  const historyNodes = useMemo(() =>
    workflow.tasks
      .reduce((acc, { statusTimeStamps, ...taskData }) => {
        // if statusTimeStamps.length <= 1 -> nothing has been updated, filter out unchanged tasks
        if (statusTimeStamps.length <= 1) {
          return acc;
        }

        return [
          ...acc,
          ...statusTimeStamps
            // remove last element from `statusTimeStamps` as it means 'created' time
            .slice(0, -1)
            // map timeStamps to object containing ts data and task data - simplyfied rendering
            .map(ts => ({ ...ts, ...taskData }))
        ];
      }, [] as TaskHistoryObject[])
      // sort data by the timeStamp `value` (means ISO datetime)
      .sort((a, b) => a.value > b.value ? -1 : a.value < b.value ? 1 : 0)
      // map items to the ReactNode - render item
      .map(item => <Accordion key={item.value.toString()}>
        <AccordionTab
          pt={{
            root: { className: 'grow-to-fill direction--column' },
            content: { className: 'grow-to-fill' }
          }}
          headerTemplate={<div className='info-tab__history--header-container space-between grow-to-fill align-items--center'>
            <div className='direction--column'>
              {generateTaskHistoryTitle(item)}
            </div>
            <span>{item.value.toFormat('dd LLL yyyy, HH:mm')} GMT</span>
          </div>}
        >
          <div className='grow-to-fill no--padding'>
            <SpecDetails
              className='info-tab__details info-tab__details--task-details grow-to-fill no--padding'
              data={generateTaskHistorySpec(item)}
            />
          </div>
        </AccordionTab>
      </Accordion>),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workflow]
  );

  return <div className='grow-to-fill direction--column workflow-details'>
    {isMobile && <header className='workflow-details__header'>
      <Button
        text
        size='small'
        onClick={closePanel}
        icon='iconoir-nav-arrow-left icon--small'
        className='workflows-page__back-button plain-text'
      >
        Back to list
      </Button>
    </header>}
    <div className='workflow-details__container'>
      {isMobile &&
        <WorkflowName
          handleRename={renameWorkflow}
          isRenaming={isRenaming}
          workflow={workflow}
        />
      }
      <nav className='workflow-details__nav'>
        {isMobile ?
          <Dropdown
            className='workflow-details__tab-select-dropdown'
            onChange={(e) => setSelectedTab(e.value)}
            value={selectedTab}
            options={[{ label: 'Progress', value: 'progress' }, { label: 'Info', value: 'info' }]}
          />
          :
          <>
            <Button
              size='small'
              className={clsx('p-button--tab-style', { 'active': selectedTab === 'progress' })}
              onClick={() => setSelectedTab('progress')}
            >
              Progress
            </Button>
            <Button
              size='small'
              className={clsx('p-button--tab-style', { 'active': selectedTab === 'info' })}
              onClick={() => setSelectedTab('info')}
            >
              Info
            </Button>
          </>}
        {selectedTab === 'progress' && <div className='workflow-details__filters-container'>
          <FilterOption
            options={assigneeOptions}
            placeholder='Assign'
            setValue={setSelectedAssignees}
            values={selectedAssignees}
          />
          <FilterOption
            options={statusOptions as FilterOptionValue[]}
            placeholder='Status'
            setValue={setSelectedStatuses}
            values={selectedStatuses}
          />
        </div>}
        <div className='workflow-details__updated-details'>
          <i className='iconoir-clock icon--small' />
          Updated: {workflow.lastModified.toFormat('dd LLL yyyy, HH:mm')} GMT
          by {formatName(workflow.lastModifiedUserName ?? workflow.requestedByName)}
        </div>
        <Button text icon='iconoir-xmark icon--tiny p-button-icon-only'
          className='close-button'
          onClick={closePanel}
        />
      </nav>
      {selectedTab === 'progress' && <div className='progress-tab__container'>
        <DataTable
          className='grouping--no-footer row--no-border workflow-details__datatable'
          groupRowsBy='grouping'
          rowGroupMode='subheader'
          expandableRowGroups
          expandedRows={expandedRows}
          onRowToggle={toggleRow}
          rowGroupHeaderTemplate={(data: ApiWorkflowTaskResponse) => data.grouping}
          value={filteredTasks}
          showHeaders={false}
          selectionMode='single'
          // NOTE: Disable selection of rows.
          // If Assignee (autocomplete) option panel is open, pressing keyboard arrows moves the focus
          // to the next 'selectable' row instead of iterating through autocomplete options.
          // isDataSelectable={(e: DataTableDataSelectableEvent): boolean => e.data.taskId === selectedTask?.taskId}
          isDataSelectable={(e: DataTableDataSelectableEvent): boolean => e.data.taskId === selectedTask?.taskId}
          onSelectionChange={(e: DataTableSelectionSingleChangeEvent<ApiWorkflowTaskResponse[]>) => toggleTaskSelection(e.value)}
          selection={selectedRowData}
          onRowClick={(e: DataTableRowClickEvent): void => toggleTaskSelection(e.data as ApiWorkflowTaskResponse)}
          footer={<footer className='space-between'>
            <Button
              text
              severity='danger'
              onClick={() => deleteWorkflow(workflow)}
            >
              Delete
            </Button>
            <Button
              outlined
              disabled={isExporting}
              icon="iconoir-download icon--small"
              onClick={() => exportWorkflow(workflow)}
            >
              Export
            </Button>
          </footer>}
        >
          <Column
            className='no--padding'
            body={(data: ApiWorkflowTaskResponse) =>
              <Task
                key={data.taskId}
                task={data}
                handleStatusChange={handleStatusChange}
                handleAssigneeChange={handleAssigneeChange}
              />}
          />
        </DataTable>
        {/* render Sidebar once here, not inside of Task component and feed it with data
        instead of rendering multiple Sidebars for each Task */}
        {selectedTask && <TaskSidebar
          task={selectedTask}
          visible={sidebarVisible}
          setVisible={(visible: boolean): void => { if (!visible) toggleTaskSelection(null); }}
          position={isMobile ? 'bottom' : 'right'}
          updateTask={updateTask}
          isUpdatingTask={isUpdatingTask}
          handleStatusChange={handleStatusChange}
          handleAssigneeChange={handleAssigneeChange}
        />}
      </div>}
      {selectedTab === 'info' && <div className='info-tab__container'>
        <SpecDetails
          className='info-tab__details'
          itemClassName='info-tab__details--item'
          data={taskSpec}
        />
        <div className='info-tab__history'>
          {historyNodes}
        </div>
      </div>}
    </div>
  </div>;
}