import type { DateTime } from 'luxon';
import type { WorkflowStatusTypeEnum } from "./WorkflowStatusTypeEnum"
import type { WorkflowCommentResponse } from './ApiWorkflowCommentResponse';

export enum TaskChangeType {
    Status = 'Status',
    Assignee = 'Assignee'
}

export type StatusTimeStamp = {
    assigneeId: string;
    assigneeName: string;
    changeType: TaskChangeType;
    key: WorkflowStatusTypeEnum;
    updatedByUserId: string;
    updatedByUserName: string;
    value: DateTime;
    note?: string;
    previousAssigneeId?: string;
    previousAssigneeName?: string;
    previousStatus?: number;
}

export type ApiWorkflowTaskResponse = {
    assignedToId: string;
    assignedToName: string;
    comments: WorkflowCommentResponse[];
    createdUtc: DateTime;
    currentStatus: WorkflowStatusTypeEnum;
    grouping: string;
    statusTimeStamps: StatusTimeStamp[];
    taskId: string;
    title: string;
}
