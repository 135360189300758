import { Button } from 'primereact/button';

interface DeleteWorkflowDialogFooterProps {
    deleteWorkflow: () => void;
    hideDialog: () => void;
    isMutating: boolean;
}

export default function DeleteWorkflowDialogFooter (props: DeleteWorkflowDialogFooterProps): JSX.Element {
    const {  deleteWorkflow, hideDialog, isMutating } = props;

    return <div className='grow-to-fill space-between'>
        <Button size='small' text onClick={hideDialog}>Cancel</Button>
        <Button
            disabled={isMutating}
            loading={isMutating}
            onClick={deleteWorkflow}
            severity='danger'
            size='small'
            outlined>Delete</Button>
    </div>
}