import { DateTime } from 'luxon';


export const wait = (ms:number) => new Promise(r => setTimeout(r, ms));

export const notNil = (val: any): boolean => {
    return val !== null && val !== undefined;
}

export const findCurrentSearch = (sorts: string[], column: string): {item: string, index: number, exists: boolean} => {
    let orderCol = {item: "", index: -1};

    let exists = sorts.some((item, index) => {
        if (item.indexOf(column) !== -1) {
            orderCol = {item, index}
            return true;
        }
        return false;
    });

    return { ...orderCol, exists };
}


/**
 * Given a passed object, and an array of strings representing one or more keys on
 * the given object, will attempt to parse the values for each key into luxon
 * DateTime instances
 *
 * @param {*} obj
 * @param {string[]} props
 * @returns {*}
 */
export const parsePropsToDateTime = (obj: any, props: string[]): any => {
	if (!Array.isArray(props)) props = [props];
	let newprops = {};
	props.map(p => newprops = {...newprops, [p]: DateTime.fromISO(obj[p], {zone: "utc"})})

	return {...obj, ...newprops};
}


export const isObjectEmpty = (obj: any): boolean => Object.keys(obj).length === 0


/**
 * Gets value by the given path. Work ONLY with objects and not arrays.
 * @param obj Object that possibly contains value under the given path
 * @param path string Path to the item value (ex. "give.me.that.value")
 * @returns any value that is under the path or undefined
 */
export const getValueByPath = <T,>(obj: T, path: string): any => {
	if (!obj || !path) {
		return;
	}

	const keys = path.split('.');
	let element: any = obj;

	for (let i = 0; i < keys.length; i++) {
		if (!element) break;

		element = element[keys[i] as keyof typeof element];
	}

	return element;
}

export const omitNull = (obj: any, ignorekeys: string[] = []) => {

	return Object.keys(obj).reduce(
		(a: any, c: string) => {
			if (obj[c] === null || ignorekeys.includes(c)) return a;

			return {...a, [c]: obj[c]}
		},
		{}
	)
}

/**
 * Clamps the value to be in the range between min and max value.
 * @param {number} value value to be clamped
 * @param {number} [min] the minimum allowed value
 * @param {number} [max] the maximum allowed value
 * @returns {number} value that is in the range between min and max
 */
export const getClampedValue = (value: number, min: number, max: number): number => {
	switch (true) {
		case (typeof min === 'number' && typeof max === 'number'):
			return Math.min(Math.max(value, min), max);
		case (typeof min === 'number' && typeof max !== 'number'):
			return Math.max(value, min);
		case (typeof min !== 'number' && typeof max === 'number'):
			return Math.min(value, max);
		default:
			return value;
	}
};