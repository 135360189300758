import { KeyboardEvent, useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import clsx from 'clsx';

import { commissionRateValidator } from 'modules/Workflows/Models/Validators';
import { COMISSION_RATE_OPTIONS, COMMISSION_RATE_FIELD, COMMISSION_RATE_VALUE_FIELD, CommissionRateType } from 'modules/Workflows/Models/CommissionRate';

import EditableContent from '../EditableContent';

import type { ValidationError } from 'joi';
import type { ApiWorkflowResponse } from 'modules/Workflows/Models/ApiWorkflowResponse';
import type { CommissionRate } from 'modules/Workflows/Services/WorkflowApi';

interface CommissionRateFieldProps {
  workflow: ApiWorkflowResponse;
  updateRate: (wf: ApiWorkflowResponse, rate: CommissionRate) => Promise<void>;
  isLoading: boolean;
};

export default function CommissionRateField(props: CommissionRateFieldProps): JSX.Element {
  const { workflow, updateRate, isLoading } = props;

  const rateInitialType = (workflow.properties.find(p => p.key === COMMISSION_RATE_FIELD)?.value ?? CommissionRateType.Standard) as CommissionRateType;
  const rateInitialValue = workflow.properties.find(p => p.key === COMMISSION_RATE_VALUE_FIELD)?.value ?? '';
  const [rateType, setRateType] = useState<CommissionRateType>(rateInitialType);
  const [rateValue, setCustomRateValue] = useState<string>(rateInitialValue);

  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string; } | null>(null);
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  useEffect(() => {
    if (showValidationErrors) {
      validate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateValue, showValidationErrors])

  const validate = async (): Promise<boolean> => {
    const data = {
      [COMMISSION_RATE_FIELD]: rateType,
      [COMMISSION_RATE_VALUE_FIELD]: rateValue
    };

    try {
      await commissionRateValidator.validateAsync(data);

      setValidationErrors(null);

      return true;
    } catch (e) {
      const errors = (e as ValidationError).details.reduce((acc, curr) => {
        return { ...acc, [curr.context?.key as string]: curr.message };
      }, {});

      setValidationErrors(errors);

      return false;
    }
  }

  const save = async (): Promise<boolean> => {
    const valid = await validate();

    if (!valid) {
      setShowValidationErrors(true);
      return false;
    }

    await updateRate(workflow, { type: rateType, value: rateValue });

    return true;
  }

  const handleEditModeChange = (editing: boolean): void => {
    if (!editing) {
      setCustomRateValue(rateInitialValue);
      setRateType(rateInitialType);
      setShowValidationErrors(false);
      setValidationErrors(null);
    }
  }

  const customRateError = showValidationErrors && validationErrors?.[COMMISSION_RATE_VALUE_FIELD];

  return <EditableContent
    onModeChange={handleEditModeChange}
    save={save}
    saveButtonLoading={isLoading}
    value={rateType === CommissionRateType.Custom ? rateInitialValue : rateInitialType}
  >
    <Dropdown
      autoFocus
      className='direction--row'
      onChange={(e) => {
        setRateType(e.value);

        if (e.value === CommissionRateType.Standard) {
          setCustomRateValue('');
        } else if (e.value === CommissionRateType.Custom) {
          setCustomRateValue(rateInitialValue);
        }
      }}
      options={COMISSION_RATE_OPTIONS}
      placeholder='Select commission rate'
      value={rateType}
      onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          // do not save on option change - show custom input
          e.stopPropagation();
        }
      }}
    />
    {rateType === CommissionRateType.Custom && <>
      <InputText
        className={clsx({ 'p-invalid': customRateError })}
        defaultValue={rateValue}
        onChange={e => setCustomRateValue(e.target.value)}
        placeholder='Add custom rate'
      />
      {customRateError && <small className="message-invalid">{customRateError}</small>}
    </>}
  </EditableContent>;
}