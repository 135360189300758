import axios, { type AxiosError } from 'axios';
import useSWR, { type Key, type KeyedMutator, useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { DateTime } from 'luxon';
import fileDownload from 'js-file-download';

import ErrorToastService from 'components/Errors/ErrorToast/Services/ErrorToastService';
import { replaceItemAt } from 'helpers/Utils/collections';
import { parsePropsToDateTime } from 'helpers/Utils/misc';

import type { ApiWorkflowPropertyKvp } from '../Models/ApiWorkflowPropertyKvp';
import type { ApiWorkflowResponse } from '../Models/ApiWorkflowResponse';
import type { ApiWorkflowTemplateResponse } from '../Models/ApiWorkflowTemplateResponse';
import type { WorkflowProvider } from '../Models/WorkflowProvider';
import type { CommissionRateType } from '../Models/CommissionRate';
import type { ApiWorkflowTaskResponse } from '../Models/ApiWorkflowTaskResponse';

const WORKFLOW_CACHE_KEY = 'Workflows-Search';

interface SearchWorkflowsDataPayload {
    searchTerm: string;
    justMyWorkflows: boolean;
    justMyTasks: boolean;
}

interface SearchWorkflowsPayload extends SearchWorkflowsDataPayload {
    workflowProvider: number;
};

interface SearchWorkflowsReturnedValue {
    data: ApiWorkflowResponse[] | undefined;
    error: AxiosError;
    isLoading: boolean;
    isValidating: boolean;
    mutate: KeyedMutator<ApiWorkflowResponse[]>;
}

export const useSearchWorkflows = (arg: SearchWorkflowsPayload): SearchWorkflowsReturnedValue => {
    const { data, error, isLoading, isValidating, mutate } = useSWR<ApiWorkflowResponse[]>(
        WORKFLOW_CACHE_KEY,
        () => WorkflowApi.searchWorkflows(WORKFLOW_CACHE_KEY, { arg }),
        {
            revalidateOnFocus: false,
        }
    );

    return { data, error, isLoading, isValidating, mutate };
}

interface DeleteWorkflowPayload {
    workflowId: string;
}

export const useDeleteWorkflow = (): { deleteWorkflow: (arg: DeleteWorkflowPayload) => Promise<string | undefined>; isMutating: boolean; error: Error | undefined } => {
    const { cache } = useSWRConfig();

    const { trigger: deleteWorkflow, isMutating, error } = useSWRMutation<string, Error, Key, DeleteWorkflowPayload>(
        `delete-workflow`,
        WorkflowApi.deleteWorkflow,
        {
            onSuccess(workflowId) {
                const currentWorkflows = (cache.get(WORKFLOW_CACHE_KEY)?.data ?? []) as ApiWorkflowResponse[];
                cache.set(WORKFLOW_CACHE_KEY, { ...cache.get(WORKFLOW_CACHE_KEY), data: currentWorkflows.filter(x => x.id !== workflowId) });
            }
        });

    return { deleteWorkflow, isMutating, error };
}

export interface StartWorkflowPayload {
    templateName: string;
    properties: ApiWorkflowPropertyKvp<string, string>[];
};

export const useStartWorkflow = (): { startWorkflow: (arg: StartWorkflowPayload) => Promise<ApiWorkflowResponse | void>; isMutating: boolean; error: Error | undefined; } => {
    const { cache } = useSWRConfig();
    const { trigger: startWorkflow, isMutating, error } = useSWRMutation<ApiWorkflowResponse | void, Error, Key, StartWorkflowPayload>(
        'start-workflow',
        WorkflowApi.startWorkflow,
        {
            onSuccess(workflow) {
                if (workflow) {
                    const currentWorkflows = (cache.get(WORKFLOW_CACHE_KEY)?.data ?? []) as ApiWorkflowResponse[];
                    cache.set(WORKFLOW_CACHE_KEY, { ...cache.get(WORKFLOW_CACHE_KEY), data: [workflow, ...currentWorkflows] });
                }
            },
        });

    return { startWorkflow, isMutating, error };
}

interface AddTaskUpdatePayload {
    provider: WorkflowProvider;
    task: ApiWorkflowTaskResponse;
    workflowId: string;
    comment?: string;
}

export const useAddTaskUpdate = (): { addTaskUpdate: (arg: AddTaskUpdatePayload) => Promise<ApiWorkflowResponse | undefined>; isMutating: boolean; error: Error | undefined } => {
    const { cache } = useSWRConfig();
    const { trigger: addTaskUpdate, isMutating, error } = useSWRMutation<ApiWorkflowResponse, Error, Key, AddTaskUpdatePayload>(
        'add-task-update',
        WorkflowApi.addTaskUpdate,
        {
            onSuccess(workflow) {
                if (workflow) {
                    const currentWorkflows = (cache.get(WORKFLOW_CACHE_KEY)?.data ?? []) as ApiWorkflowResponse[];
                    const index = currentWorkflows.findIndex(wf => wf.id === workflow.id);

                    cache.set(WORKFLOW_CACHE_KEY, { ...cache.get(WORKFLOW_CACHE_KEY), data: replaceItemAt(currentWorkflows, workflow, index) });
                }
            }
        });

    return { addTaskUpdate, isMutating, error };
}

interface ExportWorkflowPayload {
    workflowId: string;
    workflowName: string;
}

export const useExportWorkflow = (): { exportWorkflow: (arg: ExportWorkflowPayload) => Promise<Blob | undefined>; isMutating: boolean; error: Error | undefined } => {
    const { trigger: exportWorkflow, error, isMutating } = useSWRMutation<Blob, Error, Key, ExportWorkflowPayload>(
        'export-workflow',
        WorkflowApi.exportWorkflow,
    );

    return { exportWorkflow, error, isMutating };
}

interface RenameWorkflowPayload {
    workflowId: string;
    newWorkflowName: string;
};

export const useRenameWorkflow = (): { renameWorkflow: (arg: RenameWorkflowPayload) => Promise<ApiWorkflowResponse | undefined>, isMutating: boolean, error: Error | undefined; } => {
    const { trigger: renameWorkflow, isMutating, error } = useSWRMutation<ApiWorkflowResponse, Error, Key, RenameWorkflowPayload>(
        `rename-workflow`,
        WorkflowApi.renameWorkflow,
    );

    return { renameWorkflow, error, isMutating };
}

export type CommissionRate = {
    type: CommissionRateType;
    value: string;
}

interface UpdateCommissionRatePayload extends CommissionRate {
    workflowId: string;
};

export const useUpdateCommissionRate = (): { updateCommissionRate: (arg: UpdateCommissionRatePayload) => Promise<ApiWorkflowResponse | undefined>, isMutating: boolean, error: Error | undefined; } => {
    const { trigger: updateCommissionRate, isMutating, error } = useSWRMutation<ApiWorkflowResponse, Error, Key, UpdateCommissionRatePayload>(
        `update-workflow-commission-rate`,
        WorkflowApi.updateCommissionRate,
    );

    return { updateCommissionRate, error, isMutating };
}

interface GetWorkflowTemplatesPayload {};

export const useGetWorkflowTemplates = (): { templatesData: ApiWorkflowTemplateResponse[] | undefined, templatesError: AxiosError | undefined, templatesLoading: boolean } => {
    const { data, error, isLoading } = useSWR<ApiWorkflowTemplateResponse[], AxiosError, Key, GetWorkflowTemplatesPayload>(
        `Workflow-Templates`,
        WorkflowApi.getTemplates,
        {
            revalidateOnFocus: false
        }
    );

    return { templatesData: data, templatesError: error, templatesLoading: isLoading };
}


export class WorkflowApi {

    static getTemplates = (): Promise<ApiWorkflowTemplateResponse[]> => {
        const method: string = 'GET';

        return axios.request({
            url: `Audit/Workflow/Templates`,
            method: method
        })
            .catch(e => {
                console.log('The back-end barfed', e);
                return;
            })
            .then(r => r?.data as ApiWorkflowTemplateResponse[])

    }

    static searchWorkflows = (url: string, params: { arg: SearchWorkflowsPayload }): Promise<ApiWorkflowResponse[]> => {
        const { workflowProvider, searchTerm, justMyWorkflows, justMyTasks } = params.arg;
        const method: string = 'POST';

        return axios.request<SearchWorkflowsPayload, { data: ApiWorkflowResponse[]; }, SearchWorkflowsDataPayload>({
            url: `Audit/Workflow/Search/${workflowProvider}`,
            method: method,
            data: {
                searchTerm,
                justMyTasks,
                justMyWorkflows
            }
        })
            .then(r => r.data.map(WorkflowApi.parseWorkflow) as ApiWorkflowResponse[])
            .catch(e => {
                console.log('The back-end barfed', e);
                throw e;
            });
    }

/*     static getWorkflows = (workflowProvider: number, filter: string): Promise<ApiWorkflowResponse[]> => {
        const method: string = 'GET';

        return axios.request({
            url: `Audit/Workflow/Search/${workflowProvider}/${filter}`,
            method: method
        })
            .catch(e => {
                console.log('The back-end barfed', e);
                return;
            })
            .then(r => r?.data as ApiWorkflowResponse[])

    } */

    static addTaskUpdate(url: string, params: { arg: AddTaskUpdatePayload }): Promise<ApiWorkflowResponse> {
        const { task, provider, workflowId, comment } = params.arg;
        const method: string = 'PATCH';

        return axios.request({
            url: `Audit/Workflow/${workflowId}/${provider}/Task/${task.taskId}`,
            method: method,
            data: {
                assigneeId: task.assignedToId,
                taskStatus: task.currentStatus,
                comments: comment ? [comment] : []
            }
        })
            .then(r => WorkflowApi.parseWorkflow(r.data) as ApiWorkflowResponse)
            .catch(e => {
                console.log('The back-end barfed', e);
                throw e;
            })
    }

    static startWorkflow = (url: string, params: { arg: StartWorkflowPayload; }): Promise<ApiWorkflowResponse | void> => {
        const { properties, templateName } = params.arg;
        const method: string = 'PUT';

        return axios.request<StartWorkflowPayload, { data: ApiWorkflowResponse; }>({
            url: `Audit/Workflow`,
            method: method,
            data: {
                workflowTemplateName: templateName,
                properties: properties
            }
        })
            .then(r => WorkflowApi.parseWorkflow(r.data) as ApiWorkflowResponse)
            .catch(e => {
                console.log('The back-end barfed', e);
                ErrorToastService.handleError(e, [400, 500, 503], 'Sorry, something has gone wrong. Please try again later.');
            })
    }

    static deleteWorkflow = (url: string, params: { arg: DeleteWorkflowPayload }): Promise<string> => {
        return axios.request({
            url: `Audit/Workflow/${ params.arg.workflowId }`,
            method: 'DELETE',
        }).then(() => params.arg.workflowId);
    }

    static exportWorkflow = (url: string, params: { arg: ExportWorkflowPayload }): Promise<Blob> => {
        const { workflowId, workflowName } = params.arg;

        return axios.request({
            url: `Audit/Workflow/Export/${ workflowId }`,
            method: 'GET',
			responseType: 'blob'
        }).then((d) => {
            //trigger download of file naming it using current date and time
            const dt: DateTime = DateTime.now();
            fileDownload(d.data, `${workflowName}-${dt.toISODate()}-${dt.hour}-${dt.minute}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

            return d.data;
        });
    }

    static renameWorkflow = (url: string, params: { arg: RenameWorkflowPayload; }): Promise<ApiWorkflowResponse> => {
        const { workflowId, newWorkflowName } = params.arg;

        return axios.request({
            url: `Audit/Workflow/${workflowId}/update-title`,
            method: 'PATCH',
            data: {
                title: newWorkflowName
            }
        })
            .then(r => WorkflowApi.parseWorkflow(r.data) as ApiWorkflowResponse)
            .catch(e => {
                console.log('The back-end barfed', e);
                throw e;
            });
    };

    static updateCommissionRate = (url: string, params: { arg: UpdateCommissionRatePayload; }): Promise<ApiWorkflowResponse> => {
        const { type, value, workflowId } = params.arg;

        return axios.request({
            url: `Audit/Workflow/${workflowId}/commission-rate`,
            method: 'PATCH',
            data: {
                type,
                value,
            }
        })
            .then(r => WorkflowApi.parseWorkflow(r.data) as ApiWorkflowResponse)
            .catch(e => {
                console.log('The back-end barfed', e);
                throw e;
            });
    };

    static parseWorkflow = (data: ApiWorkflowResponse): ApiWorkflowResponse => {
        return {
            ...parsePropsToDateTime(data, ['createdUtc', 'lastModified']) as ApiWorkflowResponse,
            tasks: data.tasks.map((t: ApiWorkflowTaskResponse) => ({
                ...parsePropsToDateTime(t, ['createdUtc']),
                statusTimeStamps: t.statusTimeStamps.map(ts => parsePropsToDateTime(ts, ['value']))
            }))
        };
    };
}
