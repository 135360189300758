import { useEffect, useState, RefObject } from 'react';
import { Button } from 'primereact/button';
import useIntersectionObserver from '@react-hook/intersection-observer';

import useDimensions from 'helpers/Hooks/Dimensions';

interface TraversalProps {
    activeTab?: number;
    container: RefObject<HTMLElement>;
    onVisibilityChange?: (arg: boolean) => void;
}

export default function Traversal (props: TraversalProps): JSX.Element {
		
	const { activeTab, container, onVisibilityChange } = props;
	const [ showTraversalElements, setShowTraversalElements ] = useState<boolean>(false);
	const { width, scrollWidth } = useDimensions(container);

	const { isIntersecting: leftMostTabVisible } = useIntersectionObserver(container.current?.firstChild as HTMLDivElement, {root: container.current, threshold: 1});
	
	const { isIntersecting: rightMostTabVisible } = useIntersectionObserver(container.current?.lastChild as HTMLDivElement, {root: container.current, threshold: 1, rootMargin: '1px'}); // not sure why `rootMargin: '1px'` works... TODO: fix (get rid of it)
	
	const [ tabIndex, setTabIndex ] = useState<number>(activeTab ?? 0);

	useEffect(() => {
		if (typeof activeTab === 'number') {
			const activeItem = Array.from(container.current?.childNodes as NodeListOf<HTMLDivElement>).at(activeTab);

			activeItem?.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
			setTabIndex(activeTab);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTab])

	useEffect(() => {

		const visible = scrollWidth > width;

		console.log("visibility", scrollWidth, width, scrollWidth > width);

		setShowTraversalElements(visible);

		if (typeof onVisibilityChange === 'function') onVisibilityChange(visible);

	}, [width, scrollWidth, onVisibilityChange])

	const handleTraversal = (direction: "left" | "right") => {
		//	guard against running when container is not defined
		if (!container.current) return;

		let currentLeftMostItem: HTMLElement | undefined;
		const children = Array.from(container.current.childNodes as NodeListOf<HTMLElement>);

		switch(direction) {
			case "left":
				currentLeftMostItem = children.at(tabIndex - 1);
				container.current.scrollLeft -= currentLeftMostItem?.offsetWidth ?? 0;
				setTabIndex(c => c - 1);
				break;
			case "right":
				currentLeftMostItem = children.at(tabIndex);
				container.current.scrollLeft += currentLeftMostItem?.offsetWidth ?? 0;
				setTabIndex(c => c + 1);
				break;
		}
	}

	return showTraversalElements ? <div className='margin--right--small'>
		<Button size="small" text
			className='plain-text'
			icon={`iconoir-nav-arrow-left icon--tiny`}
			onClick={() => handleTraversal("left")}
			disabled={leftMostTabVisible}
		/>
		<Button size="small" text
			className='plain-text'
			icon={`iconoir-nav-arrow-right icon--tiny`}
			onClick={() => handleTraversal("right")}
			disabled={rightMostTabVisible}
		/>
	</div> : <></>
}